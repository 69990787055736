<template>
  <div>

    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Kategori Pengeluaran</b>
      </div>
      <b-button
        squared
        variant="success"
        @click="btnAddOnClick"
        v-b-modal.modal-form
        v-if="manipulateBtn == true"
      >Tambah</b-button>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <Table
              :editTarget="editTarget"
              purpose="master"
              ref="table"
              @btnEditOnClick="btnEditOnClick"
              @btnDeleteOnClick="btnDeleteOnClick"
            />
          </template>
        </Card>
      </div>
    </div>

    <b-modal
      id="modal-form"
      :static="true"
      title="Tambah Kategori Pengeluaran"
      @ok="modalOnSubmit"
      @hidden="modalOnHide"
    >
      <Form
        :form="form"
        :route="route"
        :editTarget="editTarget"
        ref="form"
        purpose="master"
        @formOnSubmit="formOnSubmit"
      />
    </b-modal>

  </div>
</template>

<script>

import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import Card from '@/view/content/Card.vue'
import Form from '@/component/expense-categories/Form.vue'
import Table from '@/component/expense-categories/Table.vue'

export default {

  name: 'List',

  components: {
    Card,
    Table,
    Form
  },

  data() {
    return {
      // Form
      form: {
        name: '',
        description: ''
      },
      // Form Route
      route: 'expense-categories',
      // Table Items
      items: [],
      // Other
      editTarget: '',
      // access management
      manipulateBtn:false,
    }
  },

  methods: {

    pagination() {
      this.$refs.table.pagination()
    },

    modalOnSubmit(evt) {
      this.$refs.form.modalOnSubmit(evt)
    },

    modalOnHide() {
      this.$refs.form.modalOnHide()
    },

    formOnSubmit() {
      this.pagination()
    },

    btnAddOnClick() {
      this.editTarget = ''
    },

    async btnEditOnClick(id) {
      this.editTarget = id.toString()
      this.form = await module.get('expense-categories/' + id)
      this.form['_method'] = 'put'
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete('expense-categories/' + id)
      // If Deleted
      if (result) {
        this.pagination()
      }
    },

     // access management
    async setActiveMenu(){

     let access_right_user = window.localStorage.getItem("access_right_display")
     let access_right = JSON.parse(access_right_user)
    
    let a
    for(a = 0; a < access_right.length; a++){
      console.log("looping")
      
      if(access_right[a] == "6007"){
        this.manipulateBtn = true
      }
      
    }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Keuangan" },
      { title: "Kategori Pengeluaran" },
    ])
    this.setActiveMenu()
  },

}
</script>

<style>
</style>